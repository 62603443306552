/*
 * @Author: xin.song 
 * @Date: 2020-06-02 10:27:31 
 * @Last Modified by: xin.song
 * @Last Modified time: 2022-06-08 14:20:33
 */
<template>
	<transition name="modal-mask">
		<div class="modal-mask" v-show="value" tabindex="999" @click="clickBackdrop" :class="{ 'modal-transparent':  !backdrop}">
			<div v-show="value" @click.stop class="modal-container">
				<slot></slot>
			</div>
		</div>
	</transition>
</template>

<!--
<vue-modal ref="vuemodal" v-model="modalname">
	<div class="modal-body-normal">
		<div class="modal-body-scroll-inside" id="modal-scroll">

		</div>
	</div>
</vue-modal>
-->

<script>
import "@/css/vue/vueModal.scss";
import {
	disableBodyScroll,
	enableBodyScroll,
	clearAllBodyScrollLocks,
} from "body-scroll-lock";

export default {
	data() {
		return {
			timer: null,
		};
	},
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		timeout: {
			type: Number,
		},
		scrollable: {
			//后面的body内容是否可以滚动
			type: Boolean,
			default: false,
		},
		showHandler: {
			//是否打开弹窗focus到input 默认false
			type: Boolean,
			default: false,
		},
		backdrop: {
			//点击空白区域是否可关闭 默认是能关闭
			type: Boolean,
			default: true,
		},
	},
	watch: {
		value(val) {
			this.init(val);
		},
	},
	methods: {
		close() {
			this.$emit("input", false);
			this.value = !this.value;
		},
		clickBackdrop() {
			if (!this.backdrop) {
				return;
			}
			this.close();
		},
		init(show) {
			const targetElement = document.querySelector(".modal-mask");
			if (show && targetElement) {
				disableBodyScroll(targetElement);
			}
			if (!show && targetElement) {
				enableBodyScroll(targetElement);
			}
		},
	},
	created() {
		this.init(this.value);
	},
};
</script>