var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _vm._v(" hi "),
    _c("a", { attrs: { href: "ezsub://business.ThemeSquareActivity" } }, [
      _vm._v("a标签跳转"),
    ]),
    _c(
      "div",
      {
        on: {
          click: function ($event) {
            return _vm.iframe()
          },
        },
      },
      [_vm._v("iframe")]
    ),
    _c(
      "div",
      {
        staticClass: "auto",
        on: {
          click: function ($event) {
            return _vm.appDownload()
          },
        },
      },
      [_vm._v("auto")]
    ),
    _c(
      "h2",
      {
        on: {
          click: function ($event) {
            return _vm.jumpToClient("ezsub://SubscribedActivity?portal=huodong")
          },
        },
      },
      [_vm._v("跳转默认链接")]
    ),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.jumpurl,
          expression: "jumpurl",
        },
      ],
      attrs: { type: "text" },
      domProps: { value: _vm.jumpurl },
      on: {
        input: function ($event) {
          if ($event.target.composing) {
            return
          }
          _vm.jumpurl = $event.target.value
        },
      },
    }),
    _c(
      "h2",
      {
        on: {
          click: function ($event) {
            return _vm.jumpToClient(_vm.jumpurl)
          },
        },
      },
      [_vm._v("跳转配置链接")]
    ),
    _c(
      "h2",
      {
        on: {
          click: function ($event) {
            return _vm.getUserInfo()
          },
        },
      },
      [_vm._v("获取用户信息")]
    ),
    _c("p", [_vm._v("token-->" + _vm._s(_vm.token))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }